import React from "react";
import logo from "./../assets/logo.png";
const App: React.FC = () => {
  return (
    <div className="flex justify-center items-center absolute bottom-0 right-0 left-0 top-0">
      <img src={logo} alt="IDS" className="w-80" />
    </div>
  );
};

export default App;
